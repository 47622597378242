import {
    Plugins,
    StatusBarStyle,
} from '@capacitor/core';

const { StatusBar } = Plugins;

class StatusBarPlugin {
    async setBackgroundColor(color) {
        return await StatusBar.setBackgroundColor({
            color
        });
    }

    async setStyle(style) {
        return await StatusBar.setStyle({
            style: StatusBarStyle[style]
        });
    }

    async show() {
        return await StatusBar.show();
    }

    async hide() {
        return await StatusBar.hide();
    }
}

export default new StatusBarPlugin;
